import { getStepWord } from "../util/data";
import { useStepper } from "../util/StepsContext";
import ReactGA from "react-ga4";

const CustomButton = ({ photo, title, value, valueKey, className = "bg-zuma-blue" }) => {
    const { step, setStep, formDetails, setFormDetails } = useStepper();
    
    return (
        <button type="button"
            onClick={() => {
                const eventData = { category: valueKey, action: getStepWord(step+1) +"_stage_completed", label: value };
                ReactGA.event(eventData);
                setFormDetails({ ...formDetails, [valueKey]: value });
                setStep(step + 1);
            }}
            className={"flex items-center justify-center text-white hover:bg-slate-100 hover:text-black transition duration-150 transform border-content border-2 border-black rounded-full text-xl p-4 font-bold w-full uppercase " + className}
        >
            {photo !== undefined && photo !== null && <img loading="lazy" src={photo} alt="button-img" className="block mx-auto h-8 px-4 object-contain" />} {title}
        </button>
    );
};
export default CustomButton;