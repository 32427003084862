import TitleBar from "../components/TitleBar";
import { getStepWord, liftsArray } from "../util/data";
import { useStepper } from "../util/StepsContext";
import ReactGA from "react-ga4";

const LiftEquipment = () => {
    const { step, setStep, formDetails, setFormDetails } = useStepper();

    const LiftItem = ({ photo, title }) => {
        return (
            <div className="flex flex-col mx-0 xl:w-3/12 md:w-3/12 w-6/12 p-2 mb-3 h-full items-center content-between">
                <img loading="lazy" src={photo} alt="Lift" className="w-full mb-2" />
                <button
                    className={`border-2 border-black ${title === "Other Lift" ? "bg-white text-black" : "bg-zuma-blue text-white"} hover:bg-white hover:text-black text-md md:text-lg font-bold w-full p-2 md:p-3 rounded-full uppercase`}
                    onClick={() => {
                        const eventData = { category: 'lift_type', action: getStepWord(step+1) +"_stage_completed", label: title  };
                        ReactGA.event(eventData);
                        setFormDetails({ ...formDetails, lift_type: title });
                        setStep(step + 1);
                    }}
                >
                    {title}
                </button>
            </div>
        );
    };

    return (
        <>
            <TitleBar title="CHOOSE YOUR AFFORDABLE LIFT EQUIPMENT!" subtitle="1. What are the type of lift you are looking for?" />
            <div className="container mx-auto p-3 py-6 text-center flex-1">
                <div className="flex flex-wrap content-center items-end h-full">
                    {
                        liftsArray.map((item, index) => <LiftItem key={index} photo={item.photo} title={item.title} />)
                    }
                </div>
                <div className="mt-6">
                    <a href="https://zumasales.com/" className="text-zuma-blue text-2xl font-light underline italic cursor-pointer transition duration-150 transform">I need lift parts instead</a>
                </div>
            </div>
        </>
    );
};
export default LiftEquipment;