import { createContext, useContext, useMemo, useState } from 'react';
const StepsContext = createContext({
    step: 0,
    setStep: () => ({ type: "error", error: new Error("not initialized") }),
    formDetails: {
        lift_type: "",
        brand: "",
        size: "",
        engineType: "",
        installment: ""
    },
    setFormDetails: () => ({ type: "error", error: new Error("not initialized") }),
});


export const StepsProvider = ({ children }) => {
    const [step, setStep] = useState(0);
    const [formDetails, setFormDetails] = useState({
        lift_type: "",
        brand: "",
        size: "",
        engineType: "",
        installment: ""
    });

    const contextValue = useMemo(() => ({
        step,
        setStep,
        formDetails,
        setFormDetails
    }), [formDetails, step]);

    return (
        <StepsContext.Provider value={contextValue}>
            {children}
        </StepsContext.Provider>
    );
};

export const useStepper = () => useContext(StepsContext);