import { useStepper } from "../util/StepsContext";

const TitleBar = ({ title = "", subtitle = "" }) => {
    const { step } = useStepper();
    let progressBarWidth = "w-2/12";
    if (step === 1) {
        progressBarWidth = "w-4/12";
    } else if (step === 2) {
        progressBarWidth = "w-6/12";
    } else if (step === 3) {
        progressBarWidth = "w-8/12";
    } else if (step === 4) {
        progressBarWidth = "w-10/12";
    } else if (step === 5) {
        progressBarWidth = "w-full";
    }

    return (
        <div className="w-full text-white">
            <div className="flex w-full p-0 bg-white">
                <div className={"flex bg-zuma-blue transition duration-150 transform p-0.5 " + progressBarWidth}></div>
            </div>
            <div className="p-3 md:p-6 h-36 md:h-36 flex items-center justify-center">
                <div className="flex flex-col justify-center">
                    {title !== "" &&
                        <h1 className="text-center bg-zuma-orange md:text-4xl text-xl font-bold mb-2 md:mb-6 px-2 py-1">{title}</h1>
                    }
                    {subtitle !== "" &&
                        <p className="text-center md:text-2xl text-md font-light text-zuma-blue">{subtitle}</p>
                    }
                </div>
            </div>
        </div>
    );
};
export default TitleBar;