import { StepsProvider } from './util/StepsContext';
import Wizard from './pages/Wizard';

function App() {
  return (
    <StepsProvider> 
      <Wizard />
    </StepsProvider>
  );
}

export default App;
