import { useEffect } from "react";
import Header from '../components/Header';
import LiftEquipment from "./LiftEquipment";
import Brand from "./Brand";
import Sizing from "./Sizing";
import EngineType from "./EngineType";
import PaymentMethod from "./PaymentMethod";
import AgentAssist from "./AgentAssist";
import { brandsArray } from "../util/data";
import { useStepper } from "../util/StepsContext";
import Intercom from '@intercom/messenger-js-sdk';
import ReactGA from "react-ga4";

ReactGA.initialize("GTM-TK3JHSD");

function Wizard() {
  const { step } = useStepper();

  const steps = [
    {
      name: "Step 1",
      component: <LiftEquipment />,
    },
    {
      name: "Step 2",
      component: <Brand />,
    },
    {
      name: "Step 3",
      component: <Sizing />,
    },
    {
      name: "Step 4",
      component: <EngineType />,
    },
    {
      name: "Step 5",
      component: <PaymentMethod />,
    },
    {
      name: "Step 6",
      component: <AgentAssist />,
    }
  ];

  useEffect(() => {
    Intercom({
      app_id: 'w78bgqdn',
      // vertical_padding: 150,
      // horizontal_padding: (window.innerWidth / 2) - 200,
      hide_default_launcher: true
    });
  }, []);
  
  return (
    <>
      <div className="flex flex-col items-center font-sans min-h-screen w-full bg-white">
        <Header />
        {steps[step].component}
        <div className="hidden">
          {brandsArray.map((b) => (
            <img src={b.photo} alt="brands" />
          ))}
        </div>
      </div>
    </>
  );
}

export default Wizard;
