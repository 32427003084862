import { useStepper } from "../util/StepsContext";

const BackButton = ({ onClick = () => {} }) => {
    const { step, setStep } = useStepper();

    return (
        <button type="button" onClick={() => { onClick(); setStep(step - 1) }} className="flex items-center justify-center bg-white text-black hover:bg-slate-100 hover:text-black transition duration-150 transform border-content border-2 border-black rounded-full text-2xl py-3 px-12 gap-2 p-2">
            <img loading="lazy" src="back-arrow.svg" alt="back-arrow" className="hover:text-black" /> Back
        </button>
    );
};
export default BackButton;