import BackButton from "../components/BackButton";
import CustomButton from "../components/CustomButton";
import TitleBar from "../components/TitleBar";
import { engineArray } from "../util/data";

const EngineType = () => {
    return (
        <>
            <TitleBar title="4. What Engine type are you looking for?" />
            <div className="container mx-auto p-3 py-12">
                <div className="flex items-center w-full">
                    <div className="engine-box flex flex-col w-64 py-2 mx-auto gap-5">
                        {engineArray.map((item, index) => <CustomButton valueKey={'engineType'} key={index} title={item.title} value={item.value} />)}
                        <CustomButton valueKey={'engineType'} title="NO PREFERENCE" value="No Preference" className="bg-black" />
                        <BackButton />
                    </div>
                </div>
            </div>
        </>
    );
};
export default EngineType;