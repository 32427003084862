import BackButton from "../components/BackButton";
import CustomButton from "../components/CustomButton";
import TitleBar from "../components/TitleBar";

const PaymentMethod = () => {
    return (
        <>
            <TitleBar title="5. Are you interested in flexible financing?" />
            <div className="container p-3 py-12 flex flex-col text-center items-stretch h-full">
                <div className="pay-box flex flex-col w-64 py-2 mx-auto gap-5">
                    <CustomButton valueKey={'installment'} title="YES" value="Yes" />
                    <CustomButton valueKey={'installment'} title="NO" value="No" />
                    <CustomButton valueKey={'installment'} title="MAYBE" value="Maybe" />
                    <BackButton />
                </div>
            </div>
        </>
    );
};
export default PaymentMethod;