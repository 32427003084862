export const liftsArray = [
    { title: "Scissor Lift", photo: "sccissors-lift.webp" },
    { title: "Boom Lift", photo: "boom-lift.webp" },
    { title: "Telehandler", photo: "telehandler-lift.webp" },
    { title: "Other Lift", photo: "other-lift.webp" }
];

export const brandsArray = [
    { photo: "genie-logo.webp", title: null, value: "Genie's" },
    { photo: "jlg-logo.webp", title: null, value: "JLG's" },
    { photo: "skyjack-logo.webp", title: null, value: "SkyJack's" },
    { photo: "haulotte-logo.webp", title: null, value: "Haulotte's" },
];

export const sizeArray = [
    { title: "19-30 FT", value: "19-30 FT" },
    { title: "30-40 FT", value: "30-40 FT" },
    { title: "40-60 FT", value: "40-60 FT" },
    { title: "60-80 FT", value: "60-80 FT" },
    { title: "80-150 FT", value: "80-150 FT" },
];

export const engineArray = [
    { title: "Electric", value: "Electric" },
    { title: "Diesel", value: "Diesel" },
    { title: "Dual Fuel", value: "Dual Fuel" },
];

export function getStepWord(step) {
    switch (step) {
        case 1:
            return 'first';
        case 2:
            return 'second';
        case 3:
            return 'third';
        case 4:
            return 'fourth';
        case 5:
            return 'fifth';
        default:
            return '';
    }
}