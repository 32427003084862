import { useEffect, useRef, useState } from "react";
import BackButton from "../components/BackButton";
import TitleBar from "../components/TitleBar";
import { useStepper } from "../util/StepsContext";
import Intercom from '@intercom/messenger-js-sdk';

const AgentAssist = () => {
    const buttonRef = useRef(null);
    const { formDetails, step, setStep } = useStepper();
    const [showFullloading, setShowFullloading] = useState(true);
    const [showloading, setShowloading] = useState(true);
    const [showThankyou, setShowThankyou] = useState(false);

    useEffect(() => {
        const updatePosition = () => {
            const ele = document.querySelector('.intercom-messenger-frame');
            const badge = document.querySelector('.hideBadge');
            if (ele) {
                if (buttonRef.current) {
                    if(window.innerWidth > 425){
                        const rect = buttonRef.current.getBoundingClientRect();
                        const bottomPosition = window.innerHeight - rect.bottom;
                        ele.style.bottom = bottomPosition + 150 + 'px';
                        ele.style.height = `calc(100vh - ${bottomPosition + 110 + 96 + 'px'})`;
                        if (badge) {
                            badge.style.bottom = bottomPosition + 112 + 'px';
                            badge.style.display = 'block';
                        }
                    } else {
                        ele.style.bottom = '';
                        ele.style.height = ``;
                        if (badge) {
                            badge.style.bottom = '';
                            badge.style.display = '';
                        }
                    }
                    
                }
            }
        };

        if (buttonRef.current && step === 5) {
            //const rect = buttonRef.current.getBoundingClientRect();
            //const bottomPosition = window.innerHeight - rect.bottom;

            let IntercomApp = window?.Intercom ?? Intercom;

            // IntercomApp({
            //     app_id: 'w78bgqdn',
            //     vertical_padding: bottomPosition + 150,
            //     horizontal_padding: (window.innerWidth / 2) - 200,
            //     hide_default_launcher: true,
            //     ...formDetails
            // });
            IntercomApp("boot", formDetails);
            IntercomApp('update', formDetails);
            IntercomApp("onShow", () => {
                setShowloading(false);
                setTimeout(() => {
                    updatePosition();
                }, 500);
                setTimeout(() => {
                    setShowFullloading(false);
                }, 1000);
            });

            IntercomApp("onHide", () => {
                setShowThankyou(true);
                setTimeout(() => {
                    window?.Intercom("shutdown");
                    window?.Intercom("boot");
                    setStep(0);
                }, 5000);
            });
            //IntercomApp("showNewMessage");
            // IntercomApp("showNewMessage", `I'm interested in ${formDetails.brand} ${formDetails.lift_type} - ${formDetails.installment === "Yes" ? "With Installments" : (formDetails.installment === "No" ? "Without Installments" : "Not decided yet on installments")}`);
            // IntercomApp("showNewMessage");
        }

        // Recalculate position on window resize
        window.addEventListener('resize', updatePosition);
        updatePosition();
        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', updatePosition);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, buttonRef]);

    useEffect(() => {
        const onBeforeUnload = (ev) => {
            console.log("Zuma Equipments")
            window?.Intercom("shutdown");
        };
        window.addEventListener("unload", onBeforeUnload);
        return () => {
            window.removeEventListener("unload", onBeforeUnload);
        };
    }, []);

    return (
        <>
            {showFullloading && <div className="full-page-loader">
                <div className="w-full mx-auto py-3 flex flex-col text-center justify-between h-full flex-1">
                    <div className="w-full bg-zuma-blue py-1"> </div>
                    <div className="flex flex-col items-center justify-center pb-5 w-full h-full">
                        <span className="loader"></span>
                        <div className="flex flex-col mt-5"><h3 className="text-zuma-blue text-2xl">Please wait</h3> <p>Loading...</p></div>
                    </div>
                </div>
            </div>}
            <TitleBar subtitle={<>You’re 1 step away from your lift equipment! <br /> Our agent will connect shortly to assist you</>} />
            <div className="w-full mx-auto py-3 pt-12 flex flex-col text-center justify-between h-full flex-1">
                <div className="flex flex-wrap content-center justify-center pb-5 w-full h-full">
                    {showloading && <span className="loader"></span>}
                    {showThankyou && <div className="flex flex-col"><h3 className="text-zuma-blue text-2xl">Thankyou for contacting us.</h3> <p>Redirecting back to home in 5 sec...</p></div>}
                </div>
                <div className="w-full bg-zuma-blue py-1"> </div>
                <div className="flex items-center justify-center w-full pb-10" ref={buttonRef}>
                    <BackButton onClick={() => {
                        window?.Intercom("shutdown");
                        window?.Intercom("boot");
                    }} />
                </div>
            </div>
            <div className="hideBadge"></div>
        </>
    );
};
export default AgentAssist;
